<template>
    <div>
        <div class="paySuccess">
            <img src="../../../images/order/success.png" alt="" style="width:240px;display: inline-block;">
            <div class="payText">支付成功 !</div>
            <button class="returnBack" @click="returnBack">返回</button>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
        if (window.history && window.history.pushState) {
            // 向历史记录中插入了当前页
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
        }
    },
    computed: {
    },
    destroyed() {
        window.removeEventListener('popstate', this.goBack, false);
    },
    methods: {
        returnBack() {
            let returnLoc = localStorage.getItem('returnLoc')
            this.$router.replace(returnLoc)
            localStorage.removeItem('returnLoc')
        },
        goBack() {
            history.pushState(null, null, document.URL);
        },
    }
}
</script>
<style lang="scss" scoped>
.paySuccess {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    text-align: center;
    margin-top: 30px;

    .payText {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 10px;
    }
}

.returnBack {
    width: 124px;
    height: 40px;
    background: #1477FD;
    border-radius: 8px;
    border: none;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    position: relative;
    margin-top: 95px;
}
</style>
